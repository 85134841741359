import React from 'react';

// Shared
import { IconFacebook, IconInstagram, IconInstrucoes, IconLinkedin, IconYoutube } from '../../shared/icons';

/**
 * Lista de Redes Sociais
 * @type {Array}
 */
export const socialMedias = [
  {
    desc: '/fabricadolivro',
    icon: <IconFacebook />,
    href: 'https://www.facebook.com/Fábrica-do-Livro-101745101238009',
  },
  {
    desc: '/fabricadolivrooficial',
    icon: <IconYoutube />,
    href: 'https://www.youtube.com/@FabricadoLivroOficial',
  },
  {
    desc: '/instrucoes.fabricadolivro.com.br',
    icon: <IconInstrucoes />,
    href: 'https://instrucoes.fabricadolivro.com.br',
  },
  {
    desc: '/fabricadolivro',
    icon: <IconInstagram />,
    href: 'https://www.instagram.com/fabricadolivro/',
  },
  {
    desc: '/fabrica-do-livro-brasil',
    icon: <IconLinkedin />,
    href: 'https://www.linkedin.com/company/28556033/',
  },
];

/**
 * Objeto Seo da Página
 * @type {Object}
 */

export const seo = {
  title: 'Contato | Impressão Sob Demanda | Fábrica do Livro',
  meta: [
    {
      name: 'description',
      content:
        'Saiba tudo sobre os canais de atendimento da Fábrica do Livro. Gráfica online para impressão sob demanda de livros e outros. Acesse!',
    },
  ],
};
