import React from 'react';
import WebFont from 'webfontloader';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import TagManager from 'react-gtm-module';
import gtag, { install } from 'ga-gtag';
import { version } from '../package.json';
import { Routes } from './routes';

import reducers from './reducers';

// styles
import GlobalStyle from './styles/global';
import ReactPixel from 'react-facebook-pixel';

// Facebook Pixel
const options = {
  autoConfig: true,
  debug: false,
};

console.log({ env: process.env.NODE_ENV, version });

ReactPixel.init('400964964142464', options);
ReactPixel.pageView();

let store = '';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)));
} else {
  store = createStore(reducers, applyMiddleware(reduxThunk));
}

const googleAdsId = 'AW-705761160';

WebFont.load({
  google: {
    families: [
      'Open Sans:300,400,600,700,800',
      'Open Sans Condensed:300,300i,600,700,800',
      'sans-serif',
    ],
  },
});

if (process.env.NODE_ENV === 'production') {
  console.log('GTM Initialized');

  // Configuração manual do GTM com URL personalizada
  const gtmId = 'GTM-TTSFMBV';
  const customGtmUrl = `https://gtm.fabricadolivro.com.br/gtm.js?id=${gtmId}&l=dataLayer`;
  const customNoscriptUrl = `https://gtm.fabricadolivro.com.br/ns.html?id=${gtmId}`;

  // Adicionar o script do GTM manualmente
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = customGtmUrl; // URL personalizada
  document.head.appendChild(script);

  // Adicionar o noscript manualmente
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `
    <iframe src="${customNoscriptUrl}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;
  document.body.appendChild(noscript);

  // Inicializar o dataLayer manualmente, se ainda não estiver inicializado
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

  // Manter a funcionalidade do react-gtm-module para gerenciar o dataLayer
  TagManager.dataLayer({
    dataLayer: window.dataLayer,
  });

  // Google Ads
  install(googleAdsId);
  gtag('js', new Date());
  gtag('config', googleAdsId);
}

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <Routes />
  </Provider>,
  document.getElementById('root')
);
