import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import {
  ButtonAuthProd,
  ButtonRequestResendFiles,
  ButtonResendFiles,
  ContainerResendFiles,
  DateObservation,
  Observations,
  RootButtonResendFiles,
  Separator,
  TiltleArcives,
  TitleAnexos,
} from '../style';

// config

import config from '../../../config';

// Node
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { toast } from 'react-toastify';

// Components
import Summary from '../../../components/Summary';
import StatusItem from './StatusItem';

// actions
import {
  modalAuthProd,
  modalCancel,
  modalConfirm,
  modalContest,
  modalResendFiles,
} from '../../../actions/modals';
import { fetchOrder } from '../../../actions/orders';

// Services
import { addProduct } from '../../../services/filter';
import { requestResendFiles } from '../../../services/order';

// utils
import { dateTransform } from '../../../utils';
import { PRODUCT_NOT_FOUND } from '../../../utils/constMessage';

// Styles
import * as Align from '../../../styles/align';
import * as Style from '../style';

class Items extends Component {
  state = {
    activeContent: {},
  };

  handleDetails(index, content) {
    this.setState(prevState => {
      const activeContent = { ...prevState.activeContent };
      if (activeContent[index] === content) {
        delete activeContent[index];
      } else {
        activeContent[index] = content;
      }
      return { activeContent };
    });
  }

  handleRequestResendFiles = async ({ pedido, pedido_item }) => {
    const response = await requestResendFiles(pedido, pedido_item);

    if (response.status === 200) {
      this.props.fetchOrder(pedido);
    } else {
      toast.warn(response.data.message);
    }
  };

  handleSearchFiles = async (pedido, item) => {
    let data = {};
    let acabamentos = [];
    let acabamento_obrigatorio;

    item.itemPedidoAcabamento.forEach(acab => {
      if (acab.acabamento.obrigatorio) {
        acabamento_obrigatorio = acab.acabamento.pk_acabamento;
      }
      acabamentos = [...acabamentos, acab.acabamento];
    });

    if (item.produto.fk_tipo_produto === config.TYPE_BOOK) {
      const dataBook = {
        orientacao: item.pedidoItemAtributoAdicional.orientacao,
        tipos_papel_miolo:
          item.pedidoItemAtributoAdicional.fk_dependencia_atributo,
        paginas_coloridas: item.pedidoItemAtributoAdicional.paginas_color,
        paginas_preto_branco: item.pedidoItemAtributoAdicional.paginas_pb,
        exemplares: item.qtd_pedido_item,
      };
      data = { ...data, ...dataBook };
    }

    const ListFinishes = {
      acabamentos,
      acabamento_obrigatorio,
    };

    data = { ...data, ...ListFinishes };

    const result = await addProduct(
      data,
      item.fk_produto,
      false,
      item.pk_pedido_item,
      pedido
    );

    if (!result) {
      toast.error(PRODUCT_NOT_FOUND);
      return false;
    }

    this.props.history.push('/envio');
  };

  render() {
    const { items } = this.props;
    if (!items) {
      return '';
    }

    return (
      <React.Fragment>
        {items.map((item, i) => {
          const verifyPaymentItem = item.pago_pedido_item === 1;
          return (
            <Style.Item key={i}>
              <Style.ItemContainer>
                <Style.ItemActionsobs>
                  <StatusItem data={item} />
                </Style.ItemActionsobs>

                <Style.ItemHeader
                  onClick={() => this.handleDetails(i, 'observations')}
                >
                  <span>
                    <span>{i + 1}.</span>
                    {item.pk_pedido_item} -{' '}
                    {item.produto.categoria.desc_categoria}
                  </span>
                  <Observations>
                    Observações
                    {this.state.active === i ? (
                      <FiChevronUp />
                    ) : (
                      <FiChevronDown style={{ marginTop: 2 }} />
                    )}
                  </Observations>
                </Style.ItemHeader>

                <Separator />

                <ContainerResendFiles>
                  <div>
                    {/* eslint-disable-next-line */}
                    {item.flags.reenviar ? (
                      item.problema_processar_arquivo == 0 ? (
                        <Style.CaptionMessage>
                          <div style={{ padding: 'auto' }}>
                            Para prosseguir, reenvie o arquivo
                            <br /> ou autorize a produção:
                          </div>
                        </Style.CaptionMessage>
                      ) : (
                        <Style.CaptionMessage>
                          <div style={{ padding: 'auto' }}>
                            Para prosseguir, reenvie o arquivo:
                          </div>
                        </Style.CaptionMessage>
                      )
                    ) : (
                      ''
                    )}
                  </div>
                  {/* eslint-disable-next-line */}
                  {item.flags.reenviar ? (
                    item.problema_processar_arquivo == 0 ? (
                      <ButtonAuthProd
                        onClick={() =>
                          this.props.modalAuthProd({
                            title: `Autorizar Produção do Item ${item.pk_pedido_item}`,
                            pk_pedido: item.fk_pedido,
                            pk_pedido_item: item.pk_pedido_item,
                          })
                        }
                        textField="Autorizar produção"
                      ></ButtonAuthProd>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                  {item.flags.reenviar ? (
                    <ButtonResendFiles
                      onClick={() =>
                        this.handleSearchFiles(item.fk_pedido, item)
                      }
                      textField="Reenviar Arquivos"
                    ></ButtonResendFiles>
                  ) : (
                    ''
                  )}
                  {item.flags.cancelaritem ? (
                    <Style.ButtonCancelItem
                      textField="Cancelar Item"
                      onClick={() =>
                        this.props.modalCancel({
                          title: `Cancelar Item ${item.pk_pedido_item}`,
                          pk_pedido: item.fk_pedido,
                          pk_pedido_item: item.pk_pedido_item,
                        })
                      }
                    ></Style.ButtonCancelItem>
                  ) : (
                    ''
                  )}
                </ContainerResendFiles>

                {item.flags.solicitarReenvio && verifyPaymentItem ? (
                  <RootButtonResendFiles>
                    <ButtonRequestResendFiles
                      textField="Solicitar reenvio de arquivos"
                      onClick={() =>
                        this.props.modalConfirm({
                          callback: this.handleRequestResendFiles,
                          title: 'Solicitar o reenvio de arquivos',
                          text: `Prezado cliente, ao clicar no link <strong>confirmar</strong> você estará parando o processo pedido! Tem certeza que deseja continuar?`,
                          data: {
                            pedido: item.fk_pedido,
                            pedido_item: item.pk_pedido_item,
                          },
                        })
                      }
                    ></ButtonRequestResendFiles>
                  </RootButtonResendFiles>
                ) : (
                  ''
                )}

                <Style.ItemActions>
                  {item.flags.contestarItem ? (
                    <Style.ButtonContest
                      onClick={() =>
                        this.props.modalContest({
                          title: `Contestar Item ${item.pk_pedido_item}`,
                          pedido_item: item.pk_pedido_item,
                          pedido: item.fk_pedido,
                          callback: this.props.fetchOrder,
                        })
                      }
                    >
                      Contestar item
                    </Style.ButtonContest>
                  ) : (
                    ''
                  )}
                  {item.flags.reenviarContestacao ? (
                    <Style.ButtonContest
                      onClick={() =>
                        this.props.modalContest({
                          title: `Contestar Item ${item.pk_pedido_item}`,
                          pedido_item: item.pk_pedido_item,
                          pedido: item.fk_pedido,
                          callback: this.props.fetchOrder,
                        })
                      }
                    >
                      Reenviar Arquivos da Contestação
                    </Style.ButtonContest>
                  ) : (
                    ''
                  )}
                </Style.ItemActions>
              </Style.ItemContainer>
              <Style.ItemContent
                className={
                  this.state.activeContent[i] === 'observations'
                    ? 'active'
                    : 'hidden'
                }
              >
                <div>
                  <Summary
                    data={item.resumo}
                    optionalfinishes={item.itemPedidoAcabamento}
                    additionalPrice={item.vlr_total_acabamento_pedido_item}
                    order={true}
                  />

                  <Align.Col className={'actions__item'}>
                    <TiltleArcives>Arquivos</TiltleArcives>
                    {item.pedidoItemArquivo.map((arquivo, i) => {
                      if (
                        arquivo.desc_arquivo_pedido_item_arquivo !== '' &&
                        arquivo.desc_arquivo_pedido_item_arquivo !== null
                      ) {
                        return (
                          <p key={i}>
                            <span>
                              {arquivo.produtoArquivo.descArquivo.desc_arquivo}
                            </span>
                            <a
                              href={
                                config.baixaArquivosArtes +
                                arquivo.desc_arquivo_pedido_item_arquivo
                              }
                              title={'Arquivos'}
                              target={'_blank'}
                              className={'actions__item-link'}
                            >
                              {arquivo.desc_arquivo_pedido_item_arquivo}
                            </a>
                          </p>
                        );
                      }
                      return (
                        <p key={i}>
                          <span>
                            {arquivo.produtoArquivo.descArquivo.desc_arquivo}
                          </span>
                          <a
                            href={
                              config.baixaArquivosArtes +
                              arquivo.arquivo_pedido_item_arquivo
                            }
                            title={'Arquivos'}
                            target={'_blank'}
                            className={'actions__item-link'}
                          >
                            {arquivo.arquivo_pedido_item_arquivo}
                          </a>
                        </p>
                      );
                    })}
                    {item.itemPedidoAcabamento.map((acabamento, i) => {
                      return (
                        <>
                          {acabamento.pedidoItemAcabamentoArquivo ? (
                            <p key={i}>
                              <span>
                                {
                                  acabamento.pedidoItemAcabamentoArquivo
                                    .acabamentoArquivo.descArquivo.desc_arquivo
                                }
                              </span>
                              <a
                                href={
                                  config.baixaArquivosArtes +
                                  acabamento.pedidoItemAcabamentoArquivo
                                    .arquivo_pedido_item_acabamento_arquivo
                                }
                                title={'Arquivos'}
                                target={'_blank'}
                                className={'actions__item-link'}
                              >
                                {
                                  acabamento.pedidoItemAcabamentoArquivo
                                    .arquivo_pedido_item_acabamento_arquivo
                                }
                              </a>
                            </p>
                          ) : (
                            ''
                          )}
                        </>
                      );
                    })}
                    <hr />
                    <Style.Obs>
                      <h3>Histórico de Observações</h3>
                      {item.obsPedidoItem
                        .slice()
                        .reverse()
                        .map((itemObs, i) => {
                          return (
                            <>
                              <p key={i} style={{}}>
                                <DateObservation>
                                  {dateTransform(
                                    itemObs.dta_inc_obs_pedido_item
                                  )}
                                </DateObservation>
                                <span>
                                  {ReactHtmlParser(itemObs.obs_pedido_item)}
                                </span>
                              </p>
                              {itemObs.obsPedidoItemAnexo &&
                                itemObs.obsPedidoItemAnexo.length > 0 && (
                                  <>
                                    <TitleAnexos>Anexos</TitleAnexos>
                                    {itemObs.obsPedidoItemAnexo.map(
                                      (anexoItem, indexAnexo) => {
                                        const date = moment(
                                          itemObs.dta_inc_obs_pedido_item,
                                          'YYYY/MM/DD'
                                        ).format('YYYY/MM/DD');
                                        return (
                                          <p key={indexAnexo}>
                                            <a
                                              href={
                                                config.baixaArquivosObs +
                                                date +
                                                '/' +
                                                item.fk_pedido +
                                                '/' +
                                                anexoItem.nome_anexo
                                              }
                                              target={'_blank'}
                                            >
                                              {anexoItem.nome_anexo}
                                            </a>
                                          </p>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                            </>
                          );
                        })}
                    </Style.Obs>
                  </Align.Col>
                </div>
              </Style.ItemContent>
              {/* <Style.ItemContent
                className={this.state.activeContent[i] === "otherContent" ? 'active' : 'hidden'}
              >
              </Style.ItemContent> */}
            </Style.Item>
          );
        })}
      </React.Fragment>
    );
  }
}

Items = connect(null, {
  fetchOrder,
  modalConfirm,
  modalCancel,
  modalAuthProd,
  modalContest,
  modalResendFiles,
})(Items);

export default withRouter(Items);
